/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import SwalToast from '../components/Common/SwalToast';
import { API_ENDPOINTS } from '../const/ApiEndpoints';

const logout = () => {
  try {
    localStorage.clear();
    window.location.reload();
    window.KEYCLOAK_CONFIG.logout();
  } catch (e) {
    console.log(e);
  }
};
export const initializeAxiosForKeycloak = () => {
  let headersCommonOptions = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  let showLoaderEvent = new Event('showLoader', { bubbles: true });
  let hideLoaderEvent = new Event('hideLoader', { bubbles: true });
  let numberOfApiCallsPending = 0;
  axios.defaults.baseURL = API_ENDPOINTS.DJANGO_SERVER_ADDRESS;
  axios.defaults.headers.common = headersCommonOptions;
  axios.interceptors.request.use((req) => {
    // `req` is the Axios request config, so you can modify
    // the `headers`.

    const token = localStorage.getItem('token');
    if (token && !req.url.startsWith('https://')) {
      req.headers.Authorization = `Bearer ${token}`;
    }
    const org = localStorage.getItem('org');
    if (org) {
      req.headers.org = org;
    }
    numberOfApiCallsPending++;
    if (req?.loader == false || req?.data?.loader == false) {
      document.dispatchEvent(hideLoaderEvent);
    } else {
      document.dispatchEvent(showLoaderEvent);
    }
    return req;
  });

  axios.interceptors.response.use(
    (response) => {
      numberOfApiCallsPending--;
      if (numberOfApiCallsPending === 0) {
        setTimeout(() => {
          document.dispatchEvent(hideLoaderEvent);
        }, 400);
      }
      return response;
    },
    async (error) => {
      numberOfApiCallsPending--;
      if (numberOfApiCallsPending === 0) {
        setTimeout(() => {
          document.dispatchEvent(hideLoaderEvent);
        }, 400);
      }
      const errorMessage = error?.response?.data?.msg
        ? typeof error?.response?.data?.msg === 'string'
          ? error.response.data.msg
          : JSON.stringify(error.response.data.msg)
        : error.message;

      if (error.response?.status === 401 && !error?.config?._retry) {
        error.config._retry = true;

        try {
          if (window.KEYCLOAK_CONFIG.isTokenExpired()) {
            console.log('Token expired. Trying to refresh...');
            SwalToast({
              icon: 'info',
              title: 'Token expired. Refreshing and resending request.',
            });

            await window.KEYCLOAK_CONFIG.updateToken(180)
              .then(() => {
                const newToken = window.KEYCLOAK_CONFIG.token;
                if (newToken) {
                  localStorage.setItem('token', newToken);
                  SwalToast({
                    icon: 'success',
                    title:
                      'Token refreshed successfully. Resending API requests.',
                  });
                  axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
                  error.config.headers.Authorization = `Bearer ${newToken}`;
                  return axios.request(error.config);
                }
              })
              .catch(() => {
                SwalToast({
                  icon: 'error',
                  title:
                    'Your session has expired. Please log in to continue using our services.',
                });
                logout();
                return Promise.reject(error);
              });
          }

          return Promise.reject(error);
        } catch (tokenRefreshError) {
          SwalToast({
            icon: 'error',
            title: 'Failed to refresh the token.',
          });
          logout();
          return Promise.reject(error);
        }
      }
      if (error.response?.data?.Msg) {
        SwalToast({
          icon: 'error',
          title: errorMessage,
        });
      }
      return Promise.reject(error);
    }
  );
};

// export default intializeAxiosForKeycloak;
